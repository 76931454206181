'use client'
import { useState } from 'react';
import { Flex, TextInput, Button, Modal, rem, Textarea, Radio } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { IconAt, IconUser } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';

const SignUpForm = ({ title, size='md' }) => {
  // const [email, setEmail] = useState('')
  // const [name, setName] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [opened, { open, close }] = useDisclosure(false);
  const iconUser = <IconUser style={{ width: rem(16), height: rem(16) }} />;
  const iconEmail = <IconAt style={{ width: rem(16), height: rem(16) }} />;

  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {
      email: '',
      name: '',
      type: 'agency',
      additionalInfo: '',
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  return (
    <>
      <Modal centered opened={opened} onClose={close} title="Request information about WeList.ai">
      <form
        onSubmit={form.onSubmit(async (values) => {
          setIsSubmitting(true)

          const submit = await fetch('/api/home-enquiry', {
            method: 'POST',
            body: JSON.stringify({
                form: values
            }),
            cache: 'no-store'
        })

        const response = await submit.json()

        if (response.message == 'success') {
            notifications.show({
                title: 'Your enquiry has been submitted',
                color: 'secondary',
                })
            setIsSubmitting(false)
            close()
        } else {
            setIsSubmitting(false)
            notifications.show({
                title: 'Enquiry failed to send',
                color: 'red',
                message: `Please check your network connection`,
                })
        }
        })}
      >
        <Flex direction='column' gap={6}>
          <TextInput 
              label="Your Name:"
              required
              leftSectionPointerEvents="none"
              leftSection={iconUser}
              placeholder='Your Name' 
              key={form.key('name')}
              {...form.getInputProps('name')}
            />
          <TextInput 
              label="Your Email:"
              required
              leftSectionPointerEvents="none"
              leftSection={iconEmail}
              placeholder='Your Email' 
              key={form.key('email')}
              {...form.getInputProps('email')}
            />
            <Radio.Group
              name="type"
              label="Select which describes you the most:"
              withAsterisk
              key={form.key('type')}
              {...form.getInputProps('type')}
            >
              <Flex direction='column' gap={6}>
                <Radio color='secondary' value="agency" onChange={() => {}} label="I am a Real Estate Agency Owner" />
                <Radio color='secondary' value="agent" onChange={() => {}} label="I am a Real Estate Agent" />
                <Radio color='secondary' title='Coming Soon' disabled value="project" onChange={() => {}} label="I am a Real Estate Project Owner" />
                <Radio color='secondary' title='Coming Soon' disabled value="developer" onChange={() => {}} label="I am a Real Estate Developer" />
                <Radio color='secondary' value="other" onChange={() => {}} label="Other" />
              </Flex>
            </Radio.Group>
            <Textarea
              label='Additional Information:'
              placeholder='Please add any information that can help Us to understand You better'
              minRows={3}
              key={form.key('additionalInfo')}
              {...form.getInputProps('additionalInfo')}
            />
        </Flex>
        <Button loading={isSubmitting} loaderProps={{ type: 'dots' }} mt={6} className='w-full' bg='secondary' type='submit'>Request Information</Button>
      </form>
      </Modal>
      <Flex>
        {/* <TextInput 
          leftSectionPointerEvents="none"
          leftSection={icon}
          onChange={(e) => {setEmail(e.target.value)}} 
          placeholder='Your Email' 
        /> */}
        <Button loading={isSubmitting} loaderProps={{ type: 'dots' }} size={size} onClick={open} bg='secondary'>{title}</Button>
      </Flex>
    </>
  );
}

export default SignUpForm