'use client'
import {
    HoverCard,
    Group,
    Button,
    UnstyledButton,
    Text,
    SimpleGrid,
    ThemeIcon,
    Anchor,
    Divider,
    Center,
    Box,
    Flex,
    Burger,
    Drawer,
    Collapse,
    ScrollArea,
    rem,
    useMantineTheme,
    Image
  } from '@mantine/core';
  import { useDisclosure } from '@mantine/hooks';
  import {
    IconDatabase,
    IconWorldWww,
    IconBrandOpenai,
    IconChartPie3,
    IconBuildingCommunity,
    IconCloudDataConnection,
    IconChevronDown,
  } from '@tabler/icons-react';
  import classes from './LoginNav.module.css';
  import Link from 'next/link';
  import SignUpForm from '@components/SignUpForm';
  
  const mockdata = [
    {
      icon: IconWorldWww,
      title: 'Modern Website Design',
      description: 'Make a great first impression',
    },
    {
      icon: IconCloudDataConnection,
      title: 'Easy-to-Use Backend',
      description: 'The Backbone of Your Business',
    },
    {
      icon: IconBrandOpenai,
      title: 'AI Integration',
      description: 'Save time and effort',
    },
    {
      icon: IconBuildingCommunity,
      title: 'MLS Integrations',
      description: 'List once and post automatically',
    },
    {
      icon: IconChartPie3,
      title: 'Analytics',
      description: 'Get real time business data',
    },
    {
      icon: IconDatabase,
      title: 'Automatic Backups',
      description: 'Better safe than sorry',
    },
  ];
  
  export default function LoginNav({ user }) {
    const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
    const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
    const theme = useMantineTheme();
  
    const links = mockdata.map((item) => (
      <UnstyledButton className={classes.subLink} key={item.title}>
        <Group wrap="nowrap" align="flex-start">
          <ThemeIcon size={34} variant="default" radius="md">
            <item.icon style={{ width: rem(22), height: rem(22) }} color={theme.colors.secondary[6]} />
          </ThemeIcon>
          <div>
            <Text size="sm" fw={500}>
              {item.title}
            </Text>
            <Text size="xs" c="dimmed">
              {item.description}
            </Text>
          </div>
        </Group>
      </UnstyledButton>
    ));
  
    return (
      <Box>
        <header className={classes.header}>
          <Group justify="space-between" h="100%">
            {/* <MantineLogo size={30} /> */}
            <Flex direction='row' align='flex-end'>
              <Link href={'/'}>
                <Image
                  h={28}
                  w={160}
                  src={'/welist-logo-full.png'}
                />
              </Link>
              {/* <Image
                ml={8}
                pb={2}
                h={20}
                // w={300}
                src={'/welist-sign.svg'}
              /> */}
            </Flex>
            <Group h="100%" gap={0} visibleFrom="sm">
              <a href="/" className={classes.link}>
                Home
              </a>
              <HoverCard width={600} position="bottom" radius="md" shadow="md" withinPortal>
                <HoverCard.Target>
                  <a href="/" className={classes.link}>
                    <Center inline>
                      <Box component="span" mr={5}>
                        Features
                      </Box>
                      <IconChevronDown
                        style={{ width: rem(16), height: rem(16) }}
                        color={theme.colors.blue[6]}
                      />
                    </Center>
                  </a>
                </HoverCard.Target>
  
                <HoverCard.Dropdown style={{ overflow: 'hidden' }}>
                  <Group justify="space-between" px="md">
                    <Text fw={500}>Features</Text>
                    {/* <Anchor href="#" fz="xs">
                      View all
                    </Anchor> */}
                  </Group>
  
                  <Divider my="sm" />
  
                  <SimpleGrid cols={2} spacing={0}>
                    {links}
                  </SimpleGrid>
  
                  <div className={classes.dropdownFooter}>
                    <Group justify="space-between">
                      <div>
                        <Text fw={500} fz="sm">
                          Need a real estate website?
                        </Text>
                        <Text size="xs" c="dimmed">
                          Sign up know, and start your integration!
                        </Text>
                      </div>
                      <SignUpForm title="Let's get started!" size='sm' />
                    </Group>
                  </div>
                </HoverCard.Dropdown>
              </HoverCard>
              {/* <a href="#" className={classes.link}>
                Learn
              </a>
              <a href="#" className={classes.link}>
                Academy
              </a> */}
            </Group>
            {user ? (
              <Group visibleFrom="sm">
                <Link href={'/dashboard'}>
                  <Button bg='secondary'>Go to Dashboard</Button>
                </Link>
              </Group>
            ) : (
                <Group visibleFrom="sm">
                  <Link href={'/login'}>
                    <Button bg='secondary'>Log In</Button>
                  </Link>
                </Group>
            )}
  
            <Burger opened={drawerOpened} onClick={toggleDrawer} hiddenFrom="sm" />
          </Group>
        </header>
  
        <Drawer
          opened={drawerOpened}
          onClose={closeDrawer}
          size="100%"
          padding="md"
          title="Navigation"
          hiddenFrom="sm"
          zIndex={1000000}
        >
          <ScrollArea h={`calc(100vh - ${rem(80)})`} mx="-md">
            <Divider my="sm" />
  
            <a href="/" className={classes.link}>
              Home
            </a>
            <UnstyledButton className={classes.link} onClick={toggleLinks}>
              <Center inline>
                <Box component="span" mr={5}>
                  Features
                </Box>
                <IconChevronDown
                  style={{ width: rem(16), height: rem(16) }}
                  color={theme.colors.blue[6]}
                />
              </Center>
            </UnstyledButton>
            <Collapse in={linksOpened}>{links}</Collapse>
            {/* <a href="/" className={classes.link}>
              Learn
            </a>
            <a href="/" className={classes.link}>
              Academy
            </a> */}
  
            <Divider my="sm" />
  
            {/* <Group justify="center" grow pb="xl" px="md">
              <Button variant="default">Log in</Button>
              <Button>Sign up</Button>
            </Group> */}
          </ScrollArea>
        </Drawer>
      </Box>
    );
  }